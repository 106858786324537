import { createSlice } from "@reduxjs/toolkit";

const defaultState = {

    paymentAdvancedFilters: {},
    initialPaymentHistory: [],
    paymentsHistory: [],
    paymentsHistoryLoader: false,
    initialLenderPaymentHistory: [],
    lenderPaymentsHistory: [],
    lenderPaymentsHistoryLoader: false,
    paymentTasksList: [],
    paymentTasksLoader: false,
    taskTimeException: false,
    paymentsInprogressList: [],
    progressTimeException: false,
    paymentsinProgressLoader: false,
    paymentDueCount:{},
    businessDate:'',
    upcomingPaymentsList:[],
    upcomingDueLoader:false,
    paymentsDueList:[],
    paymentsPastDueLoader:false,
    paginationFiltersFutureDue: {},
    paginationFiltersPastDue: {},
    paginationFiltersInProgress: {}

}

const commonReducer = createSlice({
    name: "commonReducer",
    initialState: defaultState,
    reducers: {
        paymentHistoryAdvancedFilters: (state, action) => {
            state = {
                ...state,
                paymentAdvancedFilters: action.payload
            }
            return state
        },
        setInitialPaymentHistory: (state, action) => {
            state = {
                ...state,
                initialPaymentHistory: action.payload
            }
            return state
        },
        setPaymentHistory: (state, action) => {
            state = {
                ...state,
                paymentsHistory: action.payload
            }
            return state
        },
        paymentHistoryLoader: (state, action) => {
            state = {
                ...state,
                paymentsHistoryLoader: action.payload
            }
            return state
        },
        setInitialLenderPaymentHistory: (state, action) => {
            state = {
                ...state,
                initialLenderPaymentHistory: action.payload
            }
            return state
        },
        setLenderPaymentsHistory: (state, action) => {
            state = {
                ...state,
                lenderPaymentsHistory: action.payload
            }
            return state
        },
        lenderPaymentHistoryLoader: (state, action) => {
            state = {
                ...state,
                lenderPaymentsHistoryLoader: action.payload
            }
            return state
        },
        setPaymentTasksList: (state, action) => {
            state = {
                ...state,
                paymentTasksList: action.payload
            }
            return state
        },
        setPaymentTasksLoader: (state, action) => {
            state = {
                ...state,
                paymentTasksLoader: action.payload
            }
            return state
        },
        updatePaymentTasksList: (state, action) => {
            state = {
                ...state,
                paymentTasksList: action?.payload ? action.payload : state.paymentTasksList
            }
            return state
        },
        setTaskTimeException: (state, action) => {
            state = {
                ...state,
                taskTimeException: action.payload
            }
            return state
        },
        updateTaskTimeException: (state, action) => {
            state = {
                ...state,
                taskTimeException: action.payload
            }
            return state
        },
        setPaymentsinProgressLoader: (state, action) => {
            state = {
                ...state,
                paymentsinProgressLoader: action.payload
            }
            return state
        },
        setProgressTimeException: (state, action) => {
            state = {
                ...state,
                progressTimeException: action.payload
            }
            return state
        },
        setPaymentsInprogressList: (state, action) => {
            state = {
                ...state,
                paymentsInprogressList: action.payload
            }
            return state
        },
        updatePaymentsInprogressList: (state, action) => {
            state = {
                ...state,
                paymentsInprogressList: action.payload
            }
            return state
        },
        setpaymentsDueCount:(state, action) => {
            state = {
                ...state,
                paymentDueCount: action.payload
            }
            return state
        },
        setBusinessDate: (state, action) => {
            state = {
                ...state,
                businessDate: action.payload
            }
            return state
        },
        setUpcomingPaymentsList:(state, action) => {
            state = {
                ...state,
                upcomingPaymentsList: action.payload
            }
            return state
        },
        updateUpcomingPaymentsList:(state, action) => {
            state = {
                ...state,
                upcomingPaymentsList: action.payload
            }
            return state
        },
        setUpcomingDueLoader:(state, action) => {
            state = {
                ...state,
                upcomingDueLoader: action.payload
            }
            return state
        },
        setPaymentsPastDueLoader:(state, action) => {
            state = {
                ...state,
                paymentsPastDueLoader: action.payload
            }
            return state
        },
        setPaymentsDueList:(state, action) => {
            state = {
                ...state,
                paymentsDueList: action.payload
            }
            return state
        },
        setPaymentsDueList:(state, action) => {
            state = {
                ...state,
                paymentsDueList: action.payload
            }
            return state
        },
        updatePaymentsDueList:(state, action) => {
            state = {
                ...state,
                paymentsDueList: action.payload
            }
            return state
        },
        setPaginationFilterFutureDue:(state, action) => {
            state = {
                ...state,
                paginationFiltersFutureDue: action.payload
            }
            return state
        },
        setPaginationFilterPastDue:(state, action) => {
            state = {
                ...state,
                paginationFiltersPastDue: action.payload
            }
            return state
        },
        setPaginationFiltersInProgress:(state, action) => {
            state = {
                ...state,
                paginationFiltersInProgress: action.payload
            }
            return state
        }
    }
})

export const { paymentHistoryAdvancedFilters, setInitialPaymentHistory, setPaymentHistory, paymentHistoryLoader, setInitialLenderPaymentHistory, setLenderPaymentsHistory, lenderPaymentHistoryLoader,
    setPaymentTasksList, setPaymentTasksLoader, updatePaymentTasksList, setTaskTimeException, updateTaskTimeException, setPaymentsinProgressLoader, setProgressTimeException, setPaymentsInprogressList,
    setpaymentsDueCount, setBusinessDate, setUpcomingPaymentsList, setUpcomingDueLoader, setPaymentsPastDueLoader, setPaymentsDueList,updatePaymentsDueList,updatePaymentsInprogressList,updateUpcomingPaymentsList, setPaginationFilterFutureDue, setPaginationFilterPastDue,setPaginationFiltersInProgress } = commonReducer.actions

export default commonReducer.reducer;