import callAPI from '../../../../commonservices/APIRequests';
import { redirectLoader, setTabStatus } from '../../../Reducers/Lender/CommonReducer/CommonReducer';
import { setSharedNotes } from '../../../Reducers/Notes';
import { removeLenderSelectedDealerId, removeLenderSelectedSupplierId, setConformationModal, setDealerId, setDefaultPaginationData, setHideNotificationIcon, setLenderBanner, setSupplierId, setActionsData, setCommonLoader, setMasterActionsData, setRolesListsData, setGlobalActionLoader, setIsRedirectToSupplier, setShortCutFilters, setIsRedirectToSupplierApproved, setIsRedirectToSupplierInventory } from '../../../Reducers/Shared';
import { getMasterMenuById } from '../../Actions';

export const setTabStatusAction = (tabKey, filters) => {
    return (dispatch) => {
        dispatch(setTabStatus({ commonTabStatus: tabKey, commonFilter: filters }));
    };
};

export const redirectToDealer = (id, currentTab, backtoDealer, redirect, historyType) => {

    return dispatch => {

        const req = {
            user_id: id
        }
        dispatch(redirectLoader(true));
        return callAPI.post('/lender/api/v1/dealer/getUserDetailsById', req)
            .then((response) => {

                if (response.status === 200) {
                    dispatch(setConformationModal(false))
                    dispatch(setHideNotificationIcon(false))
                    dispatch(removeLenderSelectedSupplierId());
                    dispatch(setDealerId({ dealerId: response.data.user._id, dealerName: response.data.user.dealerName, dealerDetails: response.data.user }));
                    dispatch(setSharedNotes())

                    setTimeout(() => {
                        redirect('/Admin/Dealerdetails', {
                            state: {
                                backtoDealersTab: backtoDealer,
                                innerActiveTab: currentTab,
                                historyType: historyType ? historyType : ''
                            }
                        })
                    }, 1000)

                    dispatch(redirectLoader(false));
                }
                else {
                    dispatch(redirectLoader(false));
                }
            })
    }
}

export const lenderBanner = (id, dealerref, isSupplier = false) => {

 
    return dispatch => {

        const req = {
            [isSupplier ? 'supplier_id' : 'dealer_id']: id,
            sopraReference: dealerref 
        }
        dispatch(redirectLoader(true));
        return callAPI.post('/lender/api/v1/banner/getLenderBannerBySopraReference', req)
            .then((response) => {

                if (response.status === 200) {
                    dispatch(setLenderBanner(response.data?.banners));
                    dispatch(redirectLoader(false));
                } else {
                    dispatch(setLenderBanner([]));
                    dispatch(redirectLoader(false));
                }
            })
    }
}

export const redirectToDealerApplication = (item, currentTab, backtoDealer, redirect, openConfigAccordian = false) => {
    return () => {

        setTimeout(() => {
            redirect('/Admin/ViewApplication', {
                state: {
                    dealerDetails: item,
                    backtoDealersTab: backtoDealer,
                    innerActiveTab: currentTab,
                    navigateToDealerSetup: true,
                    openConfigAccordian: openConfigAccordian
                }
            })
        }, 1000)
    }
}

export const redirectToEditPolicy = (id, currentTab, backtoDealer, redirect) => {
    return dispatch => {

        const req = {
            user_id: id
        }
        dispatch(redirectLoader(true));
        return callAPI.post('/lender/api/v1/dealer/getUserDetailsById', req)
            .then((response) => {

                if (response.status === 200) {
                    dispatch(getMasterMenuById(response.data.user?._id))
                    dispatch(setDealerId({ dealerId: response.data.user._id, dealerName: response.data.user.dealerName, dealerDetails: response.data.user }))

                    setTimeout(() => {
                        redirect('/Admin/DealerProfile', {
                            state: {
                                backtoDealersTab: backtoDealer,
                                innerActiveTab: currentTab,
                                breadCrumbLink: 'policyOverview',
                                breadCrumbName: 'policyOverview',
                            }
                        })
                    }, 1000)
                    dispatch(redirectLoader(false));
                }
                else {
                    dispatch(redirectLoader(false));
                }
            })
    }
}






export const redirectToSupplier = (id, currentTab, backtoSupplier, redirect) => {
    return dispatch => {

        const req = {
            user_id: id
        }
        dispatch(redirectLoader(true));
        return callAPI.post('/lender/api/v1/dealer/getUserDetailsById', req)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(removeLenderSelectedDealerId());
                    dispatch(setHideNotificationIcon(false))
                    dispatch(setSupplierId({ supplierID: response.data.user.sopraSupplierId, supplierName: response.data.user.dealerName, supplierReference: response.data.user.sopraSupplierReference, supplierDetails: response.data.user }))
                    dispatch(setConformationModal(false))
                    dispatch(setShortCutFilters({}))
                    setTimeout(() => {
                        redirect('/Admin/SupplierDetails', {
                            state: {
                                backtoDealersTab: backtoSupplier,
                                innerActiveTab: currentTab
                            }
                        })
                    }, 1000)
                    dispatch(setIsRedirectToSupplier(true))
                    dispatch(setIsRedirectToSupplierApproved(true))
                    dispatch(setIsRedirectToSupplierInventory(true))
                    dispatch(redirectLoader(false));
                }
                else {

                    dispatch(redirectLoader(false));
                }
            })
    }
}


export const redirectToVehicle = (vehicleDetails, currentTab, backtoSupplier, redirect, historyType, noteType, activeDealerTab = "") => {
    return dispatch => {

        if (vehicleDetails.vin && vehicleDetails.vin.indexOf('SUN') === 0)
            return dispatch({ type: null });

        dispatch(redirectLoader(true));

        const req = {
            user_id: vehicleDetails.dealerId
        }
        return callAPI.post('/lender/api/v1/dealer/getUserDetailsById', req)
            .then((response) => {

                if (response.status === 200) {
                    dispatch(setConformationModal(false))
                    dispatch(setDealerId({ dealerId: response.data.user._id, dealerName: response.data.user.dealerName, dealerDetails: response.data.user }))

                    dispatch({ type: 'setSharedNotes' })
                    setTimeout(() => {
                        redirect(['MyInventory'].includes(currentTab) ? '/Dealer/VehicleDetails' : currentTab === "supplier" ? '/Supplier/VehicleDetails' : '/Admin/vehicleDetails', {
                            state: {
                                backtoDealersTab: backtoSupplier,
                                innerActiveTab: currentTab,
                                vin: vehicleDetails.vin,
                                documentTabSelected: vehicleDetails.documentTabSelected,
                                vehicleId: vehicleDetails.vehicleId,
                                loanId: vehicleDetails.loanId,
                                historyType: historyType,
                                vehicleStatus: vehicleDetails.vehicleStatus,
                                noteType: noteType,
                                activeDealerTab: activeDealerTab,
                                userId: vehicleDetails?.dealerId,
                                sopraSupplierReference: vehicleDetails?.sopraSupplierReference
                            }
                        })
                    }, 1000)
                    dispatch(redirectLoader(false));
                }
                else {
                    dispatch(redirectLoader(false));
                }
            })
    }
}

export const redirectToSupplierApplication = (item, currentTab, backtoTab, redirect, setActiveTabName, openConfigAccordian = false) => {
    return () => {

        setTimeout(() => {
            redirect('/Admin/ViewSupplierApplication', {
                state: {
                    supplierDetails: item,
                    backtoDealersTab: backtoTab,
                    innerActiveTab: currentTab,
                    navigateToDealerSetup: setActiveTabName ? false : true,
                    supplierApplicationActiveTab: setActiveTabName,
                    openConfigAccordian: openConfigAccordian
                }
            })
        }, 1000)
    }
}

export const getActionsData = (currentTab, masterData, requestPayload, actionsData = []) => {
    const currentTabData = masterData?.length > 0 && masterData?.find((item) => item.route?.toLowerCase() === currentTab?.toLowerCase())
    let req = {}
    if (requestPayload?.isMasterActionsType) {
        req = {
            tabId: currentTab,
            organization: requestPayload.organization,
            userRole: requestPayload?.role
        }
        if (requestPayload?.isMasterActionsType === 'Roles') {
            req.roleId = requestPayload?.userId || null
        }else{
            req.user_id = requestPayload?.userId || null
        }
    } else {
        req = {
            user_id: requestPayload?.userId,
            roleId: JSON.parse(localStorage.getItem('user'))?.roleId || null
        }
        if (currentTabData?.innerItems?.length > 0) {
            req.parentTabId = currentTabData?._id
        } else {
            req.tabId = currentTabData?._id || currentTab
        }
    }

    return dispatch => {
        dispatch(setCommonLoader(true));
        dispatch(setGlobalActionLoader(true))

        return callAPI.get(`/workflow/api/v1/menu/${requestPayload?.isMasterActionsType ? "getMasterActions" : 'getActions'}`, req)
            .then((response) => {

                if (response.status === 200) {
                    dispatch(setCommonLoader(false));
                    if (requestPayload?.isMasterActionsType) {
                        dispatch(setMasterActionsData(response.data?.actions))
                    } else {
                        dispatch(setActionsData([...actionsData, ...response.data?.actions]));
                    }
                    dispatch(setGlobalActionLoader(false))
                } else {
                    dispatch(setCommonLoader(false));
                    dispatch(setGlobalActionLoader(false))
                    dispatch(setMasterActionsData([]))
                    dispatch(setActionsData([]));
                }
            })
    }
}

export const getPaginationData = () => {
    return dispatch => {

        return callAPI.post('/workflow/api/v1/classification/getValues', { type: 'Pagination' })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setDefaultPaginationData(response.data?.classifications[0]?.metadata ?? []))
                }
                else {
                    dispatch(setDefaultPaginationData([]))
                }
            })
    }
}

export const getUserRolesList = (organization = '') => {

    return dispatch => {
        let req = {
            includeAdmin: false
        }
        if (organization === 'dealer') {
            req.organization = "Dealer"
        } else {
            req.organization = "Supplier"
        }

        return callAPI.get('/workflow/api/v1/menu/getRoles', req).then((response) => {
            if (response.status === 200) {
                if (response.data.roles.length > 0) {
                    const tempData = response.data.roles?.map((item) => {
                        return { label: item.role, value: item.role }
                    })
                    dispatch(setRolesListsData(tempData))

                }
            } else {
                dispatch(setRolesListsData([]))

            }
        })
    }
}
