import moment from 'moment';
import Holidays from 'date-holidays';
import CryptoJS from 'crypto-js';
import store from '../store/Reducers';
import { setBusinessDate } from '../store/Reducers/Lender/Payments/Payments';
import _ from 'lodash';
const partnerDetails = localStorage.getItem('partnerDetails') ? JSON.parse(localStorage.getItem('partnerDetails')) : {};
const userDetails = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
// 


export const currencifyInDollars = (amount = '', showDollar = true) => {

    let isNegativeNumber = false;
    const concatString = showDollar ? '$' : '';

    amount = amount ? amount.toString() : '0';

    if (amount.indexOf('-') > -1) {
        amount = amount.replace('-', '');
        isNegativeNumber = true;
    }

    let amt = ''
    const decimalPart = amount.indexOf('.') > -1 ? amount.slice(amount.indexOf('.'), amount.length) : '';
    amount = amount.indexOf('.') > -1 ? amount.slice(0, amount.indexOf('.')) : amount;

    while (true) {

        let amountLength = amount.length;
        if (amountLength > 3) {
            amt = amount.slice(amountLength - 3, amountLength) + ',' + amt;
            amount = amount.slice(0, amountLength - 3)
        }
        else {
            amt = amount ? amount + ',' + amt : '0';
            break;
        }

    }

    const finalamount = concatString + amt.slice(0, amt.length - 1) + decimalPart;

    return isNegativeNumber ? '-' + finalamount : finalamount;

}

export const fileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const replaceAllExceptDigits = (value) => {

    return value.replace(/[^0-9]/g, '');

}

export const convertToBaseFormatDate = (value, isFromDatabase = false, showTime = true, monthFormat = 'MMMM', documents = false) => {

    if (!value)
        return '';

    if (isFromDatabase) {

        value = new Date(value);

        if (documents)
            return moment.utc(value).format('MM/DD/YYYY');

        if (showTime)
            return moment.utc(value).format(monthFormat + ' D, YYYY hh:mm A');
        else
            return moment.utc(value).format(monthFormat + ' D, YYYY');
    }
    else
        return moment(value).format(monthFormat + ' D, YYYY');

}

export const convertToBaseFormatMobile = (value) => {

    if (value) {

        if (value.toString().length === 10) {

            const arr = value.toString().match(/^(\d{3})(\d{3})(\d{4})$/);

            if (arr)
                return '(' + arr[1] + ') ' + arr[2] + '-' + arr[3];
            else
                return "";

        }
        else
            return value;

    }
    else
        return '';

}

export const breakText = (value, length) => {

    var words = value.split(" ");
    for (var j = 0; j < words.length; j++) {
        var l = words[j].length;
        if (l > length) {
            var result = [], i = 0;
            while (i < l) {
                result.push(words[j].substr(i, length))
                i += length;
            }
            words[j] = result.join(" ");
        }
    }
    return words.join(" ");

}

export const viewDocument = (fileType, url, noBase64inString = false) => {

    if (noBase64inString || url?.indexOf('base64') > -1) {

        var binaryString = noBase64inString ? atob(url) : atob(url.substring(url.indexOf('base64,') + 7));

        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: fileType });
        const constructedURL = window.URL.createObjectURL(blob);
        const newTab = window.open(constructedURL);

        if (fileType && fileType.toLowerCase().indexOf('image') === -1 && fileType.toLowerCase().indexOf('pdf') === -1 && fileType.toLowerCase() !== 'text/plain') {

            setTimeout(() => {
                newTab.close();
            }, 2000)

        }

    }
    else {

        const newTab = window.open(url);

        if (fileType && fileType.toLowerCase().indexOf('image') === -1 && fileType.toLowerCase().indexOf('pdf') === -1 && fileType.toLowerCase() !== 'text/plain') {

            setTimeout(() => {
                newTab.close();
            }, 2000)

        }

    }

}

export const add5DaysExcludingHolidays = (date) => {

    if (date) {

        const holidays = new Holidays('US');
        let count = 0;

        do {

            date.setDate(date.getDate() + 1);

            if (!holidays.isHoliday(date) && (date.getDay() !== 0 && date.getDay() !== 6))
                count++;

        } while (count < 5)

        return moment(date).format('MMMM D, YYYY');

    }
    else
        return '';

}

export const addCustomExcludingHolidays = (date, noOfDays) => {

    if (date) {

        const holidays = new Holidays('US');
        let count = 0;

        do {

            date.setDate(date.getDate() + 1);

            if (!holidays.isHoliday(date) && (date.getDay() !== 0 && date.getDay() !== 6))
                count++;

        } while (count < noOfDays)

        return moment(date).format('MMMM D, YYYY');

    }
    else
        return '';

}

export const downloadFile = async (url, fileName) => {
    try {
        const response = await fetch(url)
        const blob = await response.blob()
        const fileUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        return true
    } catch (error) {
        return false
    }



}

export const encrypt = (value) => {

    if (value)
        return CryptoJS.AES.encrypt(value, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    else
        return '';

}

export const decrypt = (value) => {

    if (value) {
        const bytes = CryptoJS.AES.decrypt(value, process.env.REACT_APP_ENCRYPTION_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    else
        return '';

}

export const convertCurrencyToShortNotation = (value) => {

    if (value) {

        const finalLength = value.toString().indexOf('.') > -1 ? value.toString().indexOf('.') : value.toString().length;
        const valueLength = value.toString().substring(0, finalLength).length;

        if (valueLength >= 10)
            value = (+value / 1000000000).toFixed(2) + 'B';
        else if (valueLength >= 7)
            value = (+value / 1000000).toFixed(2) + 'M';
        else if (valueLength >= 4)
            value = (+value / 1000).toFixed(2) + 'K';
        else
            value = (+value).toFixed(2);

        return value;

    }
    else
        return '';

}

export const maskAccountNumber = (value) => {

    if (value) {

        const valueLength = value.toString().length;

        if (valueLength > 6)
            value = value.substring(0, 2) + '*'.repeat(valueLength - 6) + value.substring(valueLength - 4);

        return value;

    }
    else
        return '';

}

export const getRiskStatusColor = (value) => {

    switch (value) {
        case 'High':
            return 'high-level-risk-bg-color';
        case 'Medium':
            return 'medium-level-risk-bg-color';
        case 'Low':
            return 'low-level-risk-bg-color';
        default:
            return 'default-level-risk-bg-color';
    }
}


export const getPaymentRiskStatusColor = (value) => {

    const green = ['PAID', 'BILLED', 'SCHEDULED'];
    const brownish = ['PAST_DUE', 'WAIVED', 'ANNULLED'];
    const red = ['PENDING', 'RESCINDED', 'PENDING_PAYOFF', 'DUE']


    if (brownish.includes(value)) {
        return 'brown'
    } else if (green.includes(value)) {
        return 'green'
    } else if (red.includes(value)) {
        return 'red'
    } else {
        return 'red'
    }
}




export const addressStringBuilder = (value) => {

    if (value) {

        const addrArray = [
            {
                prefix: '',
                val: value.addressLine1
            },
            {
                prefix: ', ',
                val: value.addressLine2,
            },
            {
                prefix: ', ',
                val: value.city,
            },
            {
                prefix: ', ',
                val: value.state,
            },
            {
                prefix: ' ',
                val: value.zipCode,
            },
        ]

        let addr = '';

        addrArray.forEach(function (item, index) {

            const data = item.val ? item.val : ''

            if (index > 0)
                addr = addrArray[index - 1].val ? addr + item.prefix + data : addr + data;
            else
                addr = addr + data;

        });

        return addr;

    }
    else
        return '';

}

export const dateDifferenceInDays = (date) => {

    const date1 = new Date();
    const date2 = new Date(date);
    date1.setHours(0, 0, 0, 0)
    date2.setHours(0, 0, 0, 0)
    const diffTime = Math.abs(date1 - date2);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;

}

export const hasBlankSpaces = (str) => {
    return str.match(/^\s+$/) !== null;
}

export const capitalize = (word) => {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const capitalizeEverySpace = (word) => {
    let wordArr = word.split(' ');
    for (let i = 0; i < wordArr.length; i++) {
        wordArr[i] = capitalize(wordArr[i]);
    }
    return wordArr.join(' ');

}

export const generateRandomId = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export const convertToSSNFormat = (ssn, mask = false) => {
    if (ssn) {
        if (mask) {
            return ssn.replace(/(\d{3})(\d{2})(\d{4})/, 'XXX-XX-$3');
        } else {
            return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
        }

    }
    else
        return '';
}

export const getDigitsOnly = (num) => {
    num = (typeof num === 'number' ? String(num) : num)
    if (num)
        return Number(num.replace(/[^0-9.-]/g, ''));
    else
        return 0;

}

export const disableWeekEnds = date => {
    const localDate = new Date(date);
    return localDate.getDay() !== 0 && localDate.getDay() !== 6;
}

export const csvData_vehicleAssetTagsData = (assetTagData = []) => {
    let final_CSV_obj = assetTagData?.reduce((csvAssetTagData, tagData) => {
        switch (tagData?.assetTagName) {
            case 'SAU':
                let SAU_obj = {
                    'SAU BOS Date': tagData?.SAU_bosDate ? convertToBaseFormatDate(tagData.SAU_bosDate, true, false) : '',
                    'SAU Funding Source': tagData?.SAU_fundingSource ?? '',
                    'SAU Down Payment': tagData?.SAU_downPayment ? tagData.SAU_downPayment : '',
                    'SAU Created By': tagData?.createdBy?.firstName ?? '',
                    'SAU createdAt': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'SAU Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'SAU Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'SAU Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'SAU Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, SAU_obj)
                break;
            case 'NSF':
                let NSF_obj = {
                    'NSF Draft Date': tagData?.NSF_draftDate ? convertToBaseFormatDate(tagData?.NSF_draftDate, true, false) : '',
                    'NSF Notice Date': tagData?.NSF_noticeDate ? convertToBaseFormatDate(tagData?.NSF_noticeDate, true, false) : '',
                    'NSF Reason': tagData?.NSF_reason ?? '',
                    'NSF Amount': tagData?.NSF_amount ? currencifyInDollars(tagData?.NSF_amount) : '',
                    'NSF Created By': tagData?.createdBy?.firstName ?? '',
                    'NSF Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'NSF Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'NSF Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'NSF Paid Amount': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.NSF_paid_amount ? tagData.NSF_paid_amount : '',
                    'NSF Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'NSF Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                };
                Object.assign(csvAssetTagData, NSF_obj)
                break;
            case 'SOT':
                let SOT_obj = {
                    'SOT Sold Date': tagData?.SOT_bosDate ? convertToBaseFormatDate(tagData?.SOT_bosDate, true, false) : '',
                    'SOT Funding Source': tagData?.SOT_fundingSource ?? '',
                    'SOT Notes': tagData?.SOT_notes ?? '',
                    'SOT Created By': tagData?.createdBy?.firstName ?? '',
                    'SOT Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'SOT Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'SOT Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'SOT Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'SOT Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, SOT_obj)
                break;
            case 'Stolen':
                let Stolen_obj = {
                    'Stolen Date Reported': tagData?.stolen_dateReported ? convertToBaseFormatDate(tagData?.stolen_dateReported, true, false) : '',
                    'Stolen Insured': tagData?.stolen_insured ? 'Yes' : '',
                    'Stolen Name of Insurance': tagData?.stolen_insured ? tagData?.stolen_insuranceName : '',
                    'Stolen Notes': tagData?.stolen_notes ?? '',
                    'Stolen Created By': tagData?.createdBy?.firstName ?? '',
                    'Stolen Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Stolen Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Stolen Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Stolen Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Stolen Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Stolen_obj)
                break;
            case 'Repo':
                let Repo_obj = {
                    'Date Repo': tagData?.repo_date ? convertToBaseFormatDate(tagData?.repo_date, true, false) : '',
                    'Repo Auction': tagData?.repo_Auction ?? '',
                    'Repo Notes': tagData?.repo_notes ?? '',
                    'Repo Created By': tagData?.createdBy?.firstName ?? '',
                    'Repo Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Repo Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Repo Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Repo Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Repo Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Repo_obj)
                break;
            case 'MOA':
                let MOA_obj = {
                    'MOA Date of audit': tagData?.MOA_auditDate ? convertToBaseFormatDate(tagData?.MOA_auditDate, true, false) : '',
                    'MOA Notes': tagData?.MOA_notes ?? '',
                    'MOA Created By': tagData?.createdBy?.firstName ?? '',
                    'MOA Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'MOA Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'MOA Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'MOA Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'MOA Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',

                }
                Object.assign(csvAssetTagData, MOA_obj)
                break;
            case 'Arbitration':
                let Arbitration_obj = {
                    'Date of Arbitration': tagData?.arbitration_date ? convertToBaseFormatDate(tagData?.arbitration_date, true, false) : '',
                    'Arbitration Notes': tagData?.arbitration_notes ?? '',
                    'Arbitration Created By': tagData?.createdBy?.firstName ?? '',
                    'Arbitration Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Arbitration Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Arbitration Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Arbitration Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Arbitration Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Arbitration_obj)
                break;
            case 'Experian':
                let Experian_obj = {
                    'Experian Activity Date': tagData?.experian_ActivityDate ? convertToBaseFormatDate(tagData?.experian_ActivityDate, true, false) : '',
                    'Experian Activity Type': tagData?.experian_ActivityType ?? '',
                    'Experian Created By': tagData?.createdBy?.firstName ?? '',
                    'Experian Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Experian Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Experian Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Experian Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Experian Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Experian_obj)
                break;
            case 'Registration Event':
                let Registration_Event_obj = {
                    'Registration Event Registration Date': tagData?.registration_date ? convertToBaseFormatDate(tagData?.registration_date, true, false) : '',
                    'Registration Event Notes': tagData?.registration_notes ?? '',
                    'Registration Event Created By': tagData?.createdBy?.firstName ?? '',
                    'Registration Event Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Registration Event Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Registration Event Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Registration Event Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Registration Event Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Registration_Event_obj)
                break;
            case 'Damage':
                let Damage_obj = {
                    'Damage Activity Date': tagData?.damage_date ? convertToBaseFormatDate(tagData?.damage_date, true, false) : '',
                    'Damage Notes': tagData?.damage_notes ?? '',
                    'Damage Created By': tagData?.createdBy?.firstName ?? '',
                    'Damage Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Damage Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Damage Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Damage Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Damage Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Damage_obj)
                break;
            case 'Structural Damage':
                let Structural_Damage_obj = {
                    'Structural Damage Activity Date': tagData?.structuralDamage_date ? convertToBaseFormatDate(tagData?.structuralDamage_date, true, false) : '',
                    'Structural Damage Notes': tagData?.structuralDamage_notes ?? '',
                    'Structural Damage Created By': tagData?.createdBy?.firstName ?? '',
                    'Structural Damage Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Structural Damage Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Structural Damage Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Structural Damage Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Structural Damage Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Structural_Damage_obj)
                break;
            case 'Collision':
                let Collision_obj = {
                    'Collision Activity Date': tagData?.collision_date ? convertToBaseFormatDate(tagData?.collision_date, true, false) : '',
                    'Collision Notes': tagData?.collision_notes ?? '',
                    'Collision Created By': tagData?.createdBy?.firstName ?? '',
                    'Collision Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Collision Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Collision Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Collision Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Collision Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Collision_obj)
                break;
            case 'Duplicate Title':
                let Duplicate_Title_obj = {
                    'Duplicate Title Date': tagData?.duplicateTitle_date ? convertToBaseFormatDate(tagData?.duplicateTitle_date, true, false) : '',
                    'Duplicate Title Notes': tagData?.duplicateTitle_notes ?? '',
                    'Duplicate Title Created By': tagData?.createdBy?.firstName ?? '',
                    'Duplicate Title Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Duplicate Title Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Duplicate Title Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Duplicate Title Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Duplicate Title Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Duplicate_Title_obj)
                break;
            case 'Title Event':
                let Title_Event_obj = {
                    'Title Event Date ': tagData?.titleEvent_date ? convertToBaseFormatDate(tagData?.titleEvent_date, true, false) : '',
                    'Title Event Notes': tagData?.titleEvent_notes ?? '',
                    'Title Event Created By': tagData?.createdBy?.firstName ?? '',
                    'Title Event Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Title Event Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Title Event Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Title Event Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Title Event Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Title_Event_obj)
                break;
            case 'Salvage':
                let Salvage_obj = {
                    'Salvage Date': tagData?.salvage_date ? convertToBaseFormatDate(tagData?.salvage_date, true, false) : '',
                    'Salvage Notes': tagData?.salvage_notes ?? '',
                    'Salvage Created By': tagData?.createdBy?.firstName ?? '',
                    'Salvage Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'Salvage Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'Salvage Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'Salvage Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'Salvage Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, Salvage_obj)
                break;
            case 'OEM Recall':
                let OEM_Recall_obj = {
                    'OEM Recall Date': tagData?.OEM_recall_date ? convertToBaseFormatDate(tagData?.OEM_recall_date, true, false) : '',
                    'OEM Recall Notes': tagData?.OEM_recall_notes ?? '',
                    'OEM Recall Make': tagData?.OEM_recall_make ?? '',
                    'OEM Recall Created By': tagData?.createdBy?.firstName ?? '',
                    'OEM Recall Created Date': tagData?.createdAt ? convertToBaseFormatDate(tagData?.createdAt, true, false) : '',
                    'OEM Recall Completed Date': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedDate ? convertToBaseFormatDate(tagData.actionCompletedDate, true, false) : '',
                    'OEM Recall Completed Notes': tagData?.assetTagStatus?.toLowerCase() === "completed" && tagData?.actionCompletedNotes ? tagData.actionCompletedNotes : '',
                    'OEM Recall Reopened By': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedBy ? tagData?.reopenedBy : '',
                    'OEM Recall Reopened Date': tagData?.assetTagStatus?.toLowerCase() === "reopened" && tagData?.reopenedAt ? convertToBaseFormatDate(tagData?.reopenedAt, true, false) : '',
                }
                Object.assign(csvAssetTagData, OEM_Recall_obj)
                break;

            default:
                break;
        }
        return csvAssetTagData;
    }, {}) ?? {};



    return final_CSV_obj;
};

export const csvData_TagsData = (tagType = "", tagData = [], showCompleteTagData = false, tagMetaData = []) => {
    /** Dynamically setting all the dealer tags data into csv **/
    const isAsset = tagType === "asset" ? true : false;
    const statusKey = !isAsset ? "tagStatus" : "assetTagStatus";
    const tagNameKey = !isAsset ? "tagName" : "assetTagName";
    const sortedWithOptions = [];
    const nameCountMap = {};
    let sortedAllTagsData = [];

    const getKeyValueDynamic = (obj, keyName) => {
        for (const key in obj) {
            if (key.toLowerCase().includes(keyName)) {
                return key;
            }
        }
        return null; // Return null if the value is not found
    };

    if (isAsset && tagData && tagData.length > 0) {
        const tagDataCopy = JSON.parse(JSON.stringify(tagData));
        tagDataCopy.sort((a, b) => a.assetTagName.localeCompare(b.assetTagName));
        // Create sorted array with modified names for duplicates
        _.forEach(tagDataCopy, ({ assetTagName, ...rest }) => {
            const isNotUnique = _.filter(tagDataCopy, { assetTagName }).length;
            let count;
            if (isNotUnique > 1) {
                count = nameCountMap[assetTagName] || 1;
                nameCountMap[assetTagName] = count + 1;
            } else {
                count = 0;
            }
            const updatedRest = {};
            _.forIn(rest, (value, key) => {
                updatedRest[count === 0 ? key : `${key} ${count}`] = value;
            });

            if (Object.keys(updatedRest).length > 0) {
                const innerUpdatedKeys = {}
                _.forEach(updatedRest, (value, key) => {
                    if (key?.toLowerCase().includes('taginfo') && count > 0) {
                        Object.keys(updatedRest?.[key]).length > 0 && _.forIn(updatedRest?.[key], (value, innerKey) => {
                            innerUpdatedKeys[`${innerKey} ${count}`] = updatedRest?.[key]?.[innerKey]
                        })
                        updatedRest[key] = innerUpdatedKeys
                    }
                })
            }
            sortedWithOptions.push({
                assetTagName,
                ...updatedRest
            });
        });
    }

    sortedAllTagsData = isAsset ? _.cloneDeep(sortedWithOptions) : tagData;


    if ((partnerDetails && partnerDetails?.dealerActions?.length && partnerDetails.dealerActions.includes("Create Dealer Tag") && tagType === "dealer") || (tagType === "asset" && partnerDetails?.enableAssetTags)) {
        /** show the tag data through partnerlevel flags only for dealer tags**/
        const tagStatusData = {
            [isAsset ? 'AssetTags' : 'DealerTags']: sortedAllTagsData?.map(obj => {
                if (obj?.[statusKey]?.toLowerCase() === "completed") {
                    return `${obj[tagNameKey]} Completed`;
                } else {
                    return obj[tagNameKey];
                }
            }).join(',') ?? ''
        }
        //let final_CSV_obj = {}
        // if (showCompleteTagData) {
        //     /** Return tag status along complete tag details like tag completed date,comments**/
        //     final_CSV_obj = tagData?.reduce((acc, currentData) => {
        //         const findInputNames = tagMetaData?.find(({ value }) => value === currentData?.[tagNameKey]);
        //         const filterInputFields = (
        //             findInputNames &&
        //                 currentData?.[statusKey]?.toLowerCase() === "completed"
        //                 ? [...(findInputNames?.tagInputFields || []), ...(findInputNames?.tagCompleteFields || [])]
        //                 : [...(findInputNames?.tagInputFields || [])]
        //         );
        //         filterInputFields.forEach(({ value, label, type }) => {
        //             if (currentData && currentData.tagInfo && value in currentData.tagInfo) {
        //                 let keyInfo = "";
        //                 if (label.includes(currentData?.[tagNameKey])) {
        //                     keyInfo = label;
        //                 } else {
        //                     keyInfo = `${currentData?.[tagNameKey]} ${label}`;
        //                 }
        //                 acc[keyInfo] = (
        //                     typeof currentData["tagInfo"][value] === "boolean" ?
        //                         (currentData["tagInfo"][value] ? "Yes" : "No") :
        //                         type?.toLowerCase().includes("amount") ?
        //                             currencifyInDollars(currentData?.["tagInfo"]?.[value]) :
        //                             type?.toLowerCase().includes("date") ?
        //                                 convertToBaseFormatDate(currentData?.["tagInfo"]?.[value], true, false) :
        //                                 currentData["tagInfo"][value]);
        //             }

        //             /**Include the created date and created fields**/
        //             if (currentData?.createdAt && currentData?.createdByName) {
        //                 acc[`${currentData?.[tagNameKey]} Created By`] = currentData["createdByName"];
        //                 acc[`${currentData?.[tagNameKey]} Created Date`] = currentData?.["createdAt"] ? convertToBaseFormatDate(currentData["createdAt"], true, false) : '';
        //             }

        //             /**If the tag status is reopen include the reopen details**/
        //             if (currentData?.[statusKey]?.toLowerCase() === "reopened") {
        //                 acc[`${currentData?.[tagNameKey]} ${currentData?.[statusKey]} By`] = currentData["actionPerformedByName"]
        //                 acc[`${currentData?.[tagNameKey]} ${currentData?.[statusKey]} Date`] = currentData?.["actionPerformedAt"] ? convertToBaseFormatDate(currentData["actionPerformedAt"], true, false) : ''
        //             }
        //         });
        //         return acc;
        //     }, {});
        //     /** reinitializing the csvobject once again by combining tag status and entire tag data**/
        //     final_CSV_obj = { ...tagStatusData, ...final_CSV_obj }
        // }
        // else {
        //     /** Return only tag status in csv**/
        //     final_CSV_obj = { ...tagStatusData }
        // }
        // return final_CSV_obj
        return tagStatusData;
    }
    return "";
}

export const getLoanTypes = (storeData, loanType = "", showTp = true, titlePresentVerified = false, approvedVehicle = false, loanSubType = "") => {

    if (loanType && showTp) {
        return storeData.masterData?.LoanTypes[loanType] ? storeData.masterData.LoanTypes[loanType] : loanType
    }

    else if (loanType && !showTp) {

        return titlePresentVerified && approvedVehicle && loanType === 'Fast Funding' ? storeData.masterData.LoanTypes[loanType] + ' TP' : (storeData.masterData.LoanTypes[loanType] ? storeData.masterData.LoanTypes[loanType] : loanType) + (loanSubType ? ' (' + loanSubType + ')' : '')
    }
}

export const viewNotesformatter = (data, limit = false, rangeStart = 0, rangeEnd = 0) => {


    if (data) {

        if (limit) {
            return data.replace(/p{|margin: 0|}|<[^>]*>?|&nbsp;/gm, '').slice(rangeStart, rangeEnd) + '...'
        }

        else {
            return data.replace(/p{|margin: 0|}|<[^>]*>?|&nbsp;/gm, '')
        }
    }
}

export const generateUsername = (firstName, lastName) => {
    firstName = firstName?.replace(/\s/g, '');
    lastName = lastName?.replace(/\s/g, '');
    return `${firstName?.toLowerCase()}${lastName?.toLowerCase()}`;
};
export const getBusinessDay = (holidatData) => {

    let currDay = new Date();

    const bankHolidays = holidatData?.map((item) => moment(item).format('MM/DD/yyyy'));

    while (true) {

        if (bankHolidays?.includes(moment(currDay).format('MM/DD/yyyy')) || currDay.getDay() % 6 === 0)
            currDay = new Date(currDay.setDate(currDay.getDate() + 1));
        else
            break;

    }
    return currDay;
}

export const MaskAccountNumber = (flag, accountNumber) => {
    if (accountNumber) {
        if (flag) {
            const renderMaskedAccountNumber = (accountNumber) => {
                const visibleDigits = accountNumber.slice(-4);
                const maskedDigits = accountNumber.slice(0, -4).replace(/./g, '*');

                return (
                    <>
                        <span className="roboto-text">{maskedDigits}</span>
                        {visibleDigits}
                    </>
                );
            };

            const updatedAccountNumber = accountNumber ? renderMaskedAccountNumber(accountNumber) : "";
            return updatedAccountNumber
        }
        else {
            return accountNumber
        }
    }

}

export const handleDataProcessing = (isLoaderEnable, isLoaded) => {

    let message = '';
    if (isLoaderEnable) {
        message = 'Data is processing';
    }
    else {
        if (isLoaded) {
            message = 'Data is processing'
        }
        else {
            message = 'No Records Found'
        }
    }

    return message;
}

export const businessDays = (isForReturnOnly = false) => {

    let currDay = new Date();
    const bankHolidays = store?.getState().sharedReducer?.masterData?.holidayDates.map((item) => moment(item).format('MM/DD/yyyy'));
    while (true) {

        if (bankHolidays.includes(moment(currDay).format('MM/DD/yyyy')) || currDay.getDay() % 6 === 0)
            currDay = new Date(currDay.setDate(currDay.getDate() + 1));
        else
            break;

    }

    if (!isForReturnOnly)
        store.dispatch(setBusinessDate(currDay));

    return currDay;
}
export const isDealerRolled = () => {
    const isLender = userDetails?.organization?.toLowerCase() === 'lender';
    const { rolledInterest } = store?.getState().sharedReducer?.lenderSelectedDealerDetails
    if (isLender && rolledInterest) {
        return true;
    }
    else if (!isLender && userDetails?.rolledInterest) {
        return true;
    }

    return false;
}


//get currentTabData
export const getCurrentTabData = (actionsData, tabId) => {
    const currentData = actionsData?.filter((item) => item.tabId === tabId)
    return currentData
}   

export const checkFileFormat = (file, setToaster, fileFormat = []) => {
    if (file?.name && !fileFormat.includes(file?.name?.toLowerCase().substring(file?.name?.lastIndexOf('.')))) {
        setToaster({
            header: 'Error',
            class: 'error-toaster',
            content: 'Invalid file format.',
            showToaster: true
        });
        return false;  // Explicitly return false if the file format is invalid
    }
    return true;  // Return true if the file format is valid
};
  
export const payloadFileFormat = (fileName, fileType) => {
    const validateFileFormat = fileName?.toLowerCase().indexOf('heic') > -1 ? 'image/heic' : fileType
    return validateFileFormat
}